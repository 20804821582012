<template>
  <v-sheet color="black">
    <v-container fluid class="pa-0">
      <v-row class="text-center ma-0">
        <v-col cols="12" class="black pa-0">
          <v-img :src="require('../assets/1-1.png')" cover>
            <v-row :class="[$vuetify.breakpoint.lg ? 'mt-12 pt-12' : 'mt-4']">
              <v-col cols="12" class="text-h3 font-weight-bold">
                Rent the Perfect Car Today
              </v-col>
              <v-col
                cols="12"
                :class="[
                  $vuetify.breakpoint.lg
                    ? 'blue-grey--text text--darken-2 subtitle-1'
                    : 'white--text body-2',
                ]"
              >
                From as low as $100 a day with limited time offer discounts.
              </v-col>
              <v-col cols="12">
                <v-row justify="center" class="pa-6" :class="[
                  $vuetify.breakpoint.lg
                    ? ''
                    : 'mb-12',
                ]">
                  <v-col cols="12" md="2">
                    <v-select
                      :items="['Model 3', 'Model Y', 'Mullen Five']"
                      label="Choose Model"
                      outlined
                      class="white"
                      hide-details
                      single-line
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-select
                      :items="['$100 to $150', '$150 to $200', '$200 to $250']"
                      label="Price Range"
                      class="white"
                      hide-details
                      outlined
                      single-line
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-btn
                      block
                      height="52"
                      color="success"
                      class="font-weight-bold"
                      elevation="0"
                    >
                      Search
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row class="text-center">
        <v-col cols="12" class="black">
          <h1 class="white--text text-h4">
            First Class Car Rental & Limousine Services
          </h1>
          <h2 class="grey--text body-1">
            We offer professional car rental & limousine services in our range
            of high-end vehicles
          </h2>
        </v-col>
        <v-col cols="12" class="black py-12">
          <v-container>
            <v-row class="mx-auto">
              <v-col
                v-for="(model, modelIndex) in models"
                :key="modelIndex"
                lg="4"
              >
                <v-img
                  :src="model.image"
                  height="297"
                  width="353"
                  class="mx-auto"
                  style="border-radius: 10px"
                >
                  <v-row align="center" class="fill-height">
                    <v-col cols="12">
                      <h2 class="white--text">
                        {{ model.title }}
                      </h2>
                    </v-col>
                  </v-row>
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" class="black pa-0">
          <v-img :src="require('../assets/teslawide.jpg')" height="520" cover>
            <v-overlay absolute>
            <v-row align="center" class="fill-height">
              <v-col>
                <h1 class="white--text">The Smoothest Ride</h1>
                <p class="white--text font-weight-bold">
                  We know the difference is in the details and that’s why our
                  car rental services, in the tourism<br />and business
                  industry, stand out for their quality and good taste, to offer
                  you a unique experience
                </p>
                <v-btn color="error"> Contact us </v-btn>
              </v-col>
            </v-row>
            </v-overlay>
          </v-img>
        </v-col>
        <v-col cols="12" class="white py-12">
          <v-container class="white">
            <h1 class="text-h3 mt-6">Why Choose Us</h1>
            <h4 class="grey--text my-6">
              Explore our first class limousine & car rental services
            </h4>
            <v-row>
              <v-col
                v-for="(whyChooseUsItem, whyChooseUsItemIndex) in whyChooseUs"
                :key="whyChooseUsItemIndex"
                lg="4"
                cols="12"
                class="my-4"
              >
                <v-icon color="success" x-large>
                  {{ whyChooseUsItem.icon }}
                </v-icon>
                <h3 class="text-h5 mt-4">
                  {{ whyChooseUsItem.title }}
                </h3>
                <p class="text-body-2">
                  {{ whyChooseUsItem.text }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" class="black">
          <h1 class="white--text text-center">Popular Choices</h1>
          <v-container>
            <v-row>
              <v-col
                v-for="(popularChoice, popularChoiceIndex) in popularChoices"
                :key="popularChoiceIndex"
                lg="4"
              >
                <v-img
                  :src="popularChoice.image"
                  height="297"
                  width="353"
                  class="mx-auto"
                >
                  <v-row align="center" class="fill-height">
                    <v-col cols="12">
                      <h2 class="white--text">
                        {{ popularChoice.title }}
                      </h2>
                    </v-col>
                  </v-row>
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    models: [
      { title: "Model 3", image: require("../assets/3-1.png") },
      { title: "Model Y", image: require("../assets/3-2.png") },
      { title: "Mullen Five", image: require("../assets/mullenfive.jpg") },
      { title: "Model S", image: require("../assets/3-3.png") },
      { title: "Model X", image: require("../assets/3-4.png") },
      {
        title: "Cybertruck",
        subtitle: "Coming Late 2021",
        image: require("../assets/3-5.png"),
      },
    ],
    whyChooseUs: [
      {
        icon: "fas fa-car-side",
        title: "Variety of Models",
        text: "Newest models available as they come out",
      },
      {
        icon: "fas fa-star",
        title: "Best Rate Guarantee",
        text: "Best rates available for car rentals",
      },
      {
        icon: "fas fa-headset",
        title: " Customer Service",
        text: "Always available customer service ",
      },
    ],
    popularChoices: [
      { title: "Model 3", image: require("../assets/3-1.png") },
      { title: "Model Y", image: require("../assets/3-2.png") },
      { title: "Model X", image: require("../assets/3-4.png") },
    ],
  }),
};
</script>
