<template>
  <v-app-bar app color="black" dark>
    <v-container class="d-flex align-center">
      <Logo />

      <v-spacer />

      <!-- <template v-for="(item, i) in items">
        <v-btn text :key="i">
          <span>{{ item.text }}</span>
        </v-btn>
      </template> -->
    </v-container>
  </v-app-bar>
</template>

<script>
import Logo from "@/components/Logo";

export default {
  components: {
    Logo,
  },

  data() {
    return {
      items: [
        { text: "Home" },
        { text: "Booking" },
        { text: "Contact" },
      ],
    };
  },
};
</script>
