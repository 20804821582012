<template>
  <v-app>
    <Navigation />

    <v-main>
      <Home />
    </v-main>
    
    <Footer />
  </v-app>
</template>

<script>
import Navigation from "./layout/Navigation";
import Footer from "./layout/Footer";
import Home from "./views/Home";

export default {
  name: "App",

  components: {
    Navigation,
    Footer,
    Home
  },

  data: () => ({
    //
  })
};
</script>
